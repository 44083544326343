var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory", attrs: { id: "purchaseOrd" } },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v(_vm._s(_vm.$t("MASTER PURCHASE ORDER")))]
            )
          ]),
          [
            !_vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch" },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: { "align-self": "flex-end" },
                        attrs: { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "550px" },
                          attrs: {
                            placeholder: _vm.$t(
                              "PLEASE ENTER THE KEYWORDS TO BE SEARCHED"
                            )
                          },
                          on: { click: _vm.searchFrom },
                          model: {
                            value: _vm.SeaCodeVal,
                            callback: function($$v) {
                              _vm.SeaCodeVal = $$v
                            },
                            expression: "SeaCodeVal"
                          }
                        }),
                        _c(
                          "el-badge",
                          {
                            staticStyle: { "margin-right": "18px" },
                            attrs: { value: _vm.selectBadge }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  plain: "",
                                  icon: "iconfont shaixuan2",
                                  size: "mini",
                                  title: _vm.$t("Display Filter")
                                },
                                on: {
                                  click: function($event) {
                                    _vm.isAdvancedSearch = true
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Filter")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.$t("Search"),
                              icon: "iconfont sousuo",
                              size: "mini",
                              type: "primary"
                            },
                            on: { click: _vm.searchFrom }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Search")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-top": "20px!important",
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 24, sm: 6, md: 6, lg: 6, xl: 6 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont tianjia",
                              size: "mini",
                              title: _vm.$t("New"),
                              plain: ""
                            },
                            on: { click: _vm.addPurOrd }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("New")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daoru6",
                              size: "mini",
                              title: _vm.$t("Import"),
                              plain: ""
                            },
                            on: { click: _vm.openimport }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Import")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daochu2",
                              size: "mini",
                              title: _vm.$t("Export"),
                              plain: ""
                            },
                            on: { click: _vm.exportOrder }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Export")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-receiving",
                              size: "mini",
                              title: _vm.$t("PdfGenerationList"),
                              plain: ""
                            },
                            on: { click: _vm.PdfGenerationList }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("PdfGenerationList")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { height: "auto" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.SeaCodeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("KEYWORDS")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.SeaCodeVal,
                                callback: function($$v) {
                                  _vm.SeaCodeVal = $$v
                                },
                                expression: "SeaCodeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.poVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("MASTER PO NUMBER")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.poVal,
                                callback: function($$v) {
                                  _vm.poVal = $$v
                                },
                                expression: "poVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.codeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("MASTER STYLE NUMBER")) + ":"
                                )
                              ]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.codeVal,
                                callback: function($$v) {
                                  _vm.codeVal = $$v
                                },
                                expression: "codeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.vendorVal.ids.length == 0
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);",
                                attrs: { id: "venSpan" }
                              },
                              [_vm._v(_vm._s(_vm.$t("VENDOR")) + ":")]
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  "collapse-tags": "",
                                  placeholder: ""
                                },
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  },
                                  change: _vm.venchang
                                },
                                model: {
                                  value: _vm.vendorVal.ids,
                                  callback: function($$v) {
                                    _vm.$set(_vm.vendorVal, "ids", $$v)
                                  },
                                  expression: "vendorVal.ids"
                                }
                              },
                              _vm._l(this.vendorData, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.isBrandBusiness
                          ? _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style:
                                      _vm.otherStatusVal == null ||
                                      _vm.otherStatusVal == ""
                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                        : "top: -10px;left: 20px;transform: translate(0, 0);",
                                    attrs: { id: "otherStatusSpan" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: ""
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.otherStatuschang
                                    },
                                    model: {
                                      value: _vm.otherStatusVal,
                                      callback: function($$v) {
                                        _vm.otherStatusVal = $$v
                                      },
                                      expression: "otherStatusVal"
                                    }
                                  },
                                  _vm._l(this.traingStatusData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isBrandBusiness
                          ? _c(
                              "el-col",
                              {
                                staticClass: "formDiv",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style:
                                      _vm.traingStatusVal == null ||
                                      _vm.traingStatusVal == ""
                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                        : "top: -10px;left: 20px;transform: translate(0, 0);",
                                    attrs: { id: "traingStatusSpan" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "collapse-tags": "",
                                      placeholder: "",
                                      clearable: ""
                                    },
                                    on: {
                                      focus: function($event) {
                                        return _vm.focusDiv($event)
                                      },
                                      blur: function($event) {
                                        return _vm.blurDiv($event)
                                      },
                                      change: _vm.traingStatuschang
                                    },
                                    model: {
                                      value: _vm.traingStatusVal,
                                      callback: function($$v) {
                                        _vm.traingStatusVal = $$v
                                      },
                                      expression: "traingStatusVal"
                                    }
                                  },
                                  _vm._l(this.traingStatusData, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.businessnoinVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                  ) + ":"
                                )
                              ]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.businessnoinVal,
                                callback: function($$v) {
                                  _vm.businessnoinVal = $$v
                                },
                                expression: "businessnoinVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.businessnooutVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("BUSINESS SERIAL NUMBER EXTERNAL")
                                  ) + ":"
                                )
                              ]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.businessnooutVal,
                                callback: function($$v) {
                                  _vm.businessnooutVal = $$v
                                },
                                expression: "businessnooutVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.batchVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("BATCH")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.batchVal,
                                callback: function($$v) {
                                  _vm.batchVal = $$v
                                },
                                expression: "batchVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.beginTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("ORDERBEINGTIME")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "prefix-icon": ""
                              },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.beginTimeVal,
                                callback: function($$v) {
                                  _vm.beginTimeVal = $$v
                                },
                                expression: "beginTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.endTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("ORDERENDTIME")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                "prefix-icon": ""
                              },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.endTimeVal,
                                callback: function($$v) {
                                  _vm.endTimeVal = $$v
                                },
                                expression: "endTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.colorVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("COLOR")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.colorVal,
                                callback: function($$v) {
                                  _vm.colorVal = $$v
                                },
                                expression: "colorVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.RemarkVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("REMARK")) + ":")]
                            ),
                            _c("el-input", {
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.RemarkVal,
                                callback: function($$v) {
                                  _vm.RemarkVal = $$v
                                },
                                expression: "RemarkVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.UTbeginTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("UPDATE START DATE")) + ":"
                                )
                              ]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.UTbeginTimeVal,
                                callback: function($$v) {
                                  _vm.UTbeginTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "UTbeginTimeVal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "formDiv",
                            attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                          },
                          [
                            _c(
                              "span",
                              {
                                style:
                                  _vm.UTendTimeVal == ""
                                    ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                    : "top: -10px;left: 20px;transform: translate(0, 0);"
                              },
                              [_vm._v(_vm._s(_vm.$t("UPDATE END DATE")) + ":")]
                            ),
                            _c("el-date-picker", {
                              staticClass: "dataicon",
                              staticStyle: { width: "100%" },
                              attrs: { type: "date", "prefix-icon": "" },
                              on: {
                                focus: function($event) {
                                  return _vm.focusDiv($event)
                                },
                                blur: function($event) {
                                  return _vm.blurDiv($event)
                                }
                              },
                              model: {
                                value: _vm.UTendTimeVal,
                                callback: function($$v) {
                                  _vm.UTendTimeVal =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "UTendTimeVal"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isAdvancedSearch
              ? _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              plain: "",
                              icon: "iconfont shaixuan1",
                              size: "mini",
                              title: _vm.$t("Hidden Filter")
                            },
                            on: {
                              click: function($event) {
                                _vm.isAdvancedSearch = false
                                _vm.getSelectBadge()
                              }
                            }
                          },
                          [_vm._v("  " + _vm._s(_vm.$t("Hidden Filter")) + " ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              title: _vm.$t("Search"),
                              icon: "iconfont sousuo",
                              size: "mini",
                              type: "primary"
                            },
                            on: { click: _vm.searchFrom }
                          },
                          [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont tianjia",
                              size: "mini",
                              title: _vm.$t("New"),
                              plain: ""
                            },
                            on: { click: _vm.addPurOrd }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("New")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daoru6",
                              size: "mini",
                              title: _vm.$t("Import"),
                              plain: ""
                            },
                            on: { click: _vm.openimport }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Import")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "iconfont daochu2",
                              size: "mini",
                              title: _vm.$t("Export"),
                              plain: ""
                            },
                            on: { click: _vm.exportOrder }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Export")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-receiving",
                              size: "mini",
                              title: _vm.$t("PdfGenerationList"),
                              plain: ""
                            },
                            on: { click: _vm.PdfGenerationList }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("PdfGenerationList")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("avue-crud", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                option: _vm.setData.tableOpt,
                data: _vm.tableData,
                "row-style": _vm.rowStyle,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange
              },
              scopedSlots: _vm._u([
                {
                  key: "Speed",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row"
                          }
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                placement: "bottom-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "500",
                                        color: "#000",
                                        "font-size": "14px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("To do list for this order")
                                        ) + ":"
                                      )
                                    ]
                                  ),
                                  _vm._l(
                                    row.PendingContent.CurrentSummaryInfos,
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("No.")) +
                                              _vm._s(item.Index) +
                                              "(" +
                                              _vm._s(item.BusinessNoIn) +
                                              ")：" +
                                              _vm._s(_vm.$t("to do a total"))
                                          )
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                              color: "#ff6a00",
                                              "font-size": "12px",
                                              "margin-left": "5px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.Count))]
                                        )
                                      ])
                                    }
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "500",
                                        color: "#000",
                                        "font-size": "14px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("To do list for other order")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._l(
                                    row.PendingContent.OtherSummaryInfos,
                                    function(item, index) {
                                      return _c("div", { key: index }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("No.")) +
                                              _vm._s(item.Index) +
                                              "(" +
                                              _vm._s(item.BusinessNoIn) +
                                              ")：" +
                                              _vm._s(_vm.$t("to do a total"))
                                          )
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "600",
                                              color: "#ff6a00",
                                              "font-size": "12px",
                                              "margin-left": "5px"
                                            }
                                          },
                                          [_vm._v(_vm._s(item.Count))]
                                        )
                                      ])
                                    }
                                  )
                                ],
                                2
                              ),
                              _c("el-link", { attrs: { type: "warning" } }, [
                                _vm._v(_vm._s(row.Speed))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                placement: "bottom-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column"
                                      }
                                    },
                                    [
                                      row.IsToLast
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500",
                                                color: "#000",
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Order All Link"))
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "icon" }, [
                                row.IsToLast
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "25px",
                                        height: "23px",
                                        "margin-right": "30px"
                                      },
                                      attrs: {
                                        src: "/img/speed/linkAll.png",
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                placement: "bottom-start"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "500",
                                            color: "#000",
                                            "font-size": "14px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Order Has No Link"))
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "icon" }, [
                                row.IsToLast == false
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "25px",
                                        height: "23px",
                                        "margin-right": "30px"
                                      },
                                      attrs: {
                                        src: "/img/speed/linkNo.png",
                                        alt: ""
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "TrackingApproveStatus",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-popover",
                        { attrs: { trigger: "hover", placement: "top" } },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "Statetip_" + row.TrackingApproveStatus
                                  )
                                )
                            )
                          ]),
                          _c(
                            "div",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v(_vm._s(_vm.formatterStatus(row)))]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "ApproveStatus",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      _c(
                        "el-popover",
                        { attrs: { trigger: "hover", placement: "top" } },
                        [
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("Statetip_" + row.ApproveStatus))
                            )
                          ]),
                          _c(
                            "div",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.formatterTrackingStatus(row))
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var size = ref.size
                    var type = ref.type
                    return [
                      row.Status == 0
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-edit-outline",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Edit")
                            },
                            on: {
                              click: function($event) {
                                return _vm.editPur(row)
                              }
                            }
                          })
                        : _vm._e(),
                      row.Status == 0 &&
                      row.ApproveStatus < 650 &&
                      row.LockedStatus != 605
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-delete-solid",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("Delete")
                            },
                            on: {
                              click: function($event) {
                                return _vm.delPur(row)
                              }
                            }
                          })
                        : _vm._e(),
                      row.Status == 1
                        ? _c("el-button", {
                            attrs: {
                              icon: "el-icon-refresh-left",
                              type: "text",
                              size: "medium",
                              title: _vm.$t("RecoverData")
                            },
                            on: {
                              click: function($event) {
                                return _vm.recover(row)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "expand",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: props.row.Details,
                            "header-cell-style": _vm.tableHeaderColor,
                            "row-class-name": _vm.tableRowClassName
                          }
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Speed",
                              align: "center",
                              label: _vm.$t("DEGREE OF COMPLETION"),
                              width: "130px;"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var index = ref.index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "row"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "light",
                                                placement: "bottom-start"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column"
                                                      }
                                                    },
                                                    [
                                                      row.PendingContent
                                                        .CurrentPendingSummarys
                                                        .length == 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "500",
                                                                color: "#000",
                                                                "font-size":
                                                                  "14px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "To do list for this order"
                                                                  )
                                                                ) +
                                                                  ":" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "nothing"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      row.PendingContent
                                                        .CurrentPendingSummarys
                                                        .length != 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "500",
                                                                color: "#000",
                                                                "font-size":
                                                                  "14px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "To do list for this order"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._l(
                                                        row.PendingContent
                                                          .CurrentPendingSummarys,
                                                        function(item, index) {
                                                          return _c(
                                                            "div",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    color:
                                                                      "#000",
                                                                    "font-size":
                                                                      "12px",
                                                                    "margin-left":
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatterColumn(
                                                                        item.Type
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              item.ProcessName !=
                                                              ""
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-weight":
                                                                          "600",
                                                                        color:
                                                                          "#000",
                                                                        "font-size":
                                                                          "12px",
                                                                        "margin-left":
                                                                          "5px",
                                                                        "margin-right":
                                                                          "5px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.ProcessName
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.ProcessName !=
                                                              ""
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle: {
                                                                        "font-weight":
                                                                          "600",
                                                                        "font-size":
                                                                          "10px",
                                                                        "margin-left":
                                                                          "5px",
                                                                        "margin-right":
                                                                          "5px"
                                                                      },
                                                                      attrs: {
                                                                        type:
                                                                          "text"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.upload(
                                                                            row,
                                                                            _vm.$index,
                                                                            size,
                                                                            type
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Click to fill in"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      row.PendingContent
                                                        .OtherPendingSummarys
                                                        .length == 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "500",
                                                                color: "#000",
                                                                "font-size":
                                                                  "14px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "To do list for other order"
                                                                  )
                                                                ) +
                                                                  ":" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "nothing"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      row.PendingContent
                                                        .OtherPendingSummarys
                                                        .length != 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "500",
                                                                color: "#000",
                                                                "font-size":
                                                                  "14px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "To do list for other order"
                                                                  )
                                                                ) + ":"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._l(
                                                        row.PendingContent
                                                          .OtherPendingSummarys,
                                                        function(item, index) {
                                                          return _c(
                                                            "div",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "600",
                                                                    color:
                                                                      "#000",
                                                                    "font-size":
                                                                      "14px",
                                                                    "margin-left":
                                                                      "5px"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.Title
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                item.OtherPendingInfos,
                                                                function(
                                                                  otherlist,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: index
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle: {
                                                                            display:
                                                                              "flex",
                                                                            "flex-direction":
                                                                              "column"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle: {
                                                                                "font-weight":
                                                                                  "400",
                                                                                color:
                                                                                  "#000",
                                                                                "font-size":
                                                                                  "10px",
                                                                                "margin-left":
                                                                                  "5px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  otherlist.NameCompany
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle: {
                                                                                "font-weight":
                                                                                  "400",
                                                                                color:
                                                                                  "#000",
                                                                                "font-size":
                                                                                  "8px",
                                                                                "margin-left":
                                                                                  "5px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  otherlist.IsPO
                                                                                ) +
                                                                                  "(" +
                                                                                  _vm._s(
                                                                                    otherlist.BusinessNoIn
                                                                                  ) +
                                                                                  "):" +
                                                                                  _vm._s(
                                                                                    otherlist.Code
                                                                                  )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle: {
                                                                                "font-weight":
                                                                                  "400",
                                                                                color:
                                                                                  "#ff6a00",
                                                                                "font-size":
                                                                                  "8px",
                                                                                "margin-left":
                                                                                  "5px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "to do a total"
                                                                                  )
                                                                                ) +
                                                                                  ":" +
                                                                                  _vm._s(
                                                                                    otherlist.Count
                                                                                  )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "el-link",
                                                { attrs: { type: "warning" } },
                                                [_vm._v(_vm._s(row.Speed))]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-f-c-c-t",
                                              staticStyle: {
                                                "margin-left": "10px"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "icon" },
                                                [
                                                  row.IsToLast
                                                    ? _c("img", {
                                                        staticStyle: {
                                                          width: "25px",
                                                          height: "23px",
                                                          "margin-right": "30px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/img/speed/linkAll.png",
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        staticStyle: {
                                                          width: "25px",
                                                          height: "23px",
                                                          "margin-right": "30px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            "/img/speed/linkNo.png",
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "BusinessNoIn",
                              label: _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                            }
                          }),
                          _vm.showOut
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "BusinessNoOut",
                                  label: _vm.$t(
                                    "BUSINESS SERIAL NUMBER EXTERNAL"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              prop: "Code",
                              label: _vm.isBrandBusiness
                                ? _vm.$t("MASTER STYLE NUMBER")
                                : _vm.$t("FINISHED ITEM NUMBER")
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "ProductName",
                              label: _vm.isBrandBusiness
                                ? _vm.$t("DESCRIPTION")
                                : _vm.$t("Product Name")
                            }
                          }),
                          !_vm.isBrandBusiness
                            ? _c("el-table-column", {
                                attrs: { prop: "Batch", label: _vm.$t("BATCH") }
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: { prop: "Color", label: _vm.$t("COLOR") }
                          }),
                          !_vm.isBrandBusiness
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "ApproveStatus",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterStatus
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Statetip_" +
                                                          scope.row
                                                            .ApproveStatus
                                                      )
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatterStatus(
                                                            scope.row
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _vm.isBrandBusiness
                            ? _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "TrackingApproveStatus",
                                  label: _vm.$t("STATUS"),
                                  formatter: _vm.formatterTrackingStatus
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                placement: "top"
                                              }
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Statetip_" +
                                                          scope.row
                                                            .TrackingApproveStatus
                                                      )
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "reference" },
                                                  slot: "reference"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatterTrackingStatus(
                                                            scope.row
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            : _vm._e(),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.$t("UPDATE TIME")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.IsApproveAfChange
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                placement: "bottom"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "Modify again after approval"
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-warning-outline",
                                                staticStyle: {
                                                  "margin-left": "3px",
                                                  "font-size": "14px"
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "10px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.transformDate(
                                                scope.row.LinkageUpdateDateTime
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "Remark", label: _vm.$t("REMARKS") }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "200px;",
                              align: "center",
                              label: _vm.$t("OPERATION")
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    var size = ref.size
                                    var type = ref.type
                                    return [
                                      row.CopyDeclarationbox1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center"
                                            },
                                            [
                                              row.CopyDeclarationbox
                                                ? _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _vm.LastSectionlist.indexOf(
                                                        row.VendorSectionId
                                                      ) == -1 &&
                                                      row.Status == 0 &&
                                                      row.CopySign != 2
                                                        ? _c("el-button", {
                                                            attrs: {
                                                              icon:
                                                                "icon-dingwei1",
                                                              type: "text",
                                                              size: "medium",
                                                              title: _vm.$t(
                                                                "Tracing"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.tracingPur(
                                                                  row
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      (row.CopySign == 2
                                                      ? false
                                                      : _vm.isBrandBusiness &&
                                                        row.Status == 0)
                                                        ? _c("el-button", {
                                                            attrs: {
                                                              icon:
                                                                "icon-shangchuan",
                                                              type: "text",
                                                              size: "medium",
                                                              title: _vm.$t(
                                                                "Upload"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.upload(
                                                                  row,
                                                                  $index,
                                                                  size,
                                                                  type
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      row.ApproveStatus >=
                                                        401 &&
                                                      !_vm.isBrandBusiness
                                                        ? _c("el-button", {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-thumb",
                                                              type: "text",
                                                              size: "medium",
                                                              title: _vm.$t(
                                                                "VIEW AUDIT INFO"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.ViewAuditInfo(
                                                                  row,
                                                                  $index,
                                                                  size,
                                                                  type
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm.CopyDeclarationStatus(
                                                        props.row.Details
                                                          .length,
                                                        row.CopySign
                                                      ) && !_vm.isBrandBusiness
                                                        ? _c("el-button", {
                                                            attrs: {
                                                              icon:
                                                                "el-icon-document-add",
                                                              type: "text",
                                                              size: "medium",
                                                              title: _vm.$t(
                                                                "CopyDeclaration"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.CopyDeclarationBtn(
                                                                  row,
                                                                  props.row
                                                                    .Details,
                                                                  $index
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("div", [
                                                row.CopySign == 2
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-cancel"
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              "/img/speed/noCopy.png",
                                                            alt: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.checkCancelBtn(
                                                                row
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            ]
                                          )
                                        : _c("div", [
                                            row.checkBox
                                              ? _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    row.checkBoxStatus
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "check-box",
                                                            style: row.CopyDeclarationId
                                                              ? "border-color: #009eff;"
                                                              : "",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.checkBtn(
                                                                  row,
                                                                  props.row
                                                                    .Details
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("div", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value: row.CopyDeclarationId
                                                                    ? true
                                                                    : false,
                                                                  expression:
                                                                    "row.CopyDeclarationId ? true : false"
                                                                }
                                                              ]
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "operation-text preservation",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.preservationCopy(
                                                              row,
                                                              props.row.Details
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.$t("Hold"))
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "operation-text cancel",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.cancelCopy(
                                                              row,
                                                              props.row.Details
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("Cancel")
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ChooseUploadBrand"),
                  visible: _vm.BranddialogTableVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.BranddialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("Brand"), "label-width": "120px" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "calc(100% - 115px)" },
                        attrs: { filterable: "", placeholder: "" },
                        model: {
                          value: _vm.brandChoose,
                          callback: function($$v) {
                            _vm.brandChoose = $$v
                          },
                          expression: "brandChoose"
                        }
                      },
                      _vm._l(_vm.brandList, function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", padding: "50px 0" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.submitBrandChoose()
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Yes")))]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.BranddialogTableVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ],
                  1
                ),
                _c("el-form-item")
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("REJECT"),
                  visible: _vm.RejectdialogTableVisible,
                  "modal-append-to-body": false,
                  width: "70%",
                  top: "1vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.RejectdialogTableVisible = $event
                  }
                }
              },
              [
                !_vm.isViewAudit
                  ? _c(
                      "el-form",
                      {
                        ref: "Rejectrules",
                        attrs: {
                          model: _vm.RejectEdit,
                          rules: _vm.Rejectrules,
                          "label-position": "top",
                          "label-width": "150px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24, sm: 24, md: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("RESUBMIT DESCRIPTION"),
                                      prop: "Remarks",
                                      rules: _vm.Rejectrules.Remarks
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "300",
                                        "show-word-limit": "",
                                        autosize: { minRows: 4, maxRows: 6 }
                                      },
                                      model: {
                                        value: _vm.RejectEdit.Remarks,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.RejectEdit,
                                            "Remarks",
                                            $$v
                                          )
                                        },
                                        expression: "RejectEdit.Remarks"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "20px 0"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.submitReject("Rejectrules")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Save")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.RejectdialogTableVisible = false
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Cancel")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("avue-crud", {
                  attrs: {
                    option: _vm.setData.tableOptRejectList,
                    data: _vm.tableDataRejectList,
                    "row-style": _vm.rowStyle,
                    page: _vm.pageRejectList
                  },
                  on: {
                    "update:page": function($event) {
                      _vm.pageRejectList = $event
                    },
                    "current-change": _vm.currentRejectListChange
                  }
                })
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.importLoad,
                    expression: "importLoad"
                  }
                ],
                attrs: {
                  title: _vm.$t("importPOList"),
                  visible: _vm.dialogTableVisible,
                  "modal-append-to-body": false,
                  width: "90%",
                  top: "2vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "topFromSearch" },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: { "align-self": "flex-end" },
                        attrs: { xs: 24, sm: 18, md: 18, lg: 18, xl: 18 }
                      },
                      [
                        _vm.ImportResult.resultsType == -1
                          ? _c(
                              "el-link",
                              { attrs: { type: "danger", underline: false } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("Total imported data") +
                                      _vm.ImportResult.cnt +
                                      _vm.$t("Total verification error data") +
                                      _vm.ImportResult.errCnt
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.ImportResult.resultsType == 1
                          ? _c(
                              "el-link",
                              { attrs: { type: "success", underline: false } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "Verify that the imported data is verified. Please confirm the import"
                                    ) +
                                      " " +
                                      _vm.$t("total") +
                                      ":" +
                                      _vm.ImportResult.cnt +
                                      _vm.$t("Contains update header total") +
                                      _vm.ImportResult.upHeadCnt +
                                      _vm.$t("Update item total") +
                                      _vm.ImportResult.upItemCnt
                                  )
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 24, sm: 6, md: 6, lg: 6, xl: 6 }
                      },
                      [
                        _vm.ImportResult.resultsType == -1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "iconfont daochu2",
                                  size: "mini",
                                  title: _vm.$t(
                                    "Exporting Verification Result"
                                  ),
                                  plain: ""
                                },
                                on: { click: _vm.exportVerificationResult }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("Exporting Verification Result")
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("avue-crud", {
                  ref: "crud",
                  attrs: {
                    option: _vm.setData.tableOptFile,
                    data: _vm.tableFileData,
                    "row-style": _vm.rowStyle
                  }
                }),
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c("el-col", {
                      attrs: { xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }
                    }),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "align-self": "flex-end"
                        },
                        attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialogTableVisible = false
                                _vm.dialogImportTableVisible = true
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Return")))]
                        ),
                        _vm.ImportResult.resultsType == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.subUpdateRangeIpm }
                              },
                              [_vm._v(_vm._s(_vm.$t("Import data")) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.importLoad,
                    expression: "importLoad"
                  }
                ],
                attrs: {
                  title: _vm.$t("IMPORT MASTER PO VIA EXCEL FILE"),
                  visible: _vm.dialogImportTableVisible,
                  "modal-append-to-body": false,
                  width: "40%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogImportTableVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c("el-col", { staticClass: "formDiv" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-message-box__status el-icon-warning"
                        },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.downTemplate }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Import the purchase order fill in the template,and download the template"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          float: "left",
                          display: "flex",
                          "align-items": "flex-start",
                          "justify-content": "flex-start",
                          "padding-top": "10px",
                          "padding-left": "39px !important"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 14 }
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              "on-change": _vm.beforeUpload,
                              "auto-upload": false,
                              multiple: false,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              accept: ".xlsx",
                              "file-list": _vm.fileList,
                              action: _vm.targetAction
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "suffix-icon":
                                  "iconfont icon-wenjianjiaFolders5",
                                size: "medium"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.uploadClick()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        staticStyle: {
                          float: "left",
                          display: "flex",
                          "align-items": "flex-start",
                          "justify-content": "flex-start",
                          "padding-top": "10px",
                          "padding-left": "39px !important"
                        },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 14 }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.importOrder }
                          },
                          [_vm._v(_vm._s(_vm.$t("Upload File")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("exportPO"),
                  visible: _vm.dialogExportVisible,
                  "modal-append-to-body": false,
                  width: "30%",
                  top: "20vh"
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogExportVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c("el-col", { staticClass: "formDiv" }, [
                      _c(
                        "div",
                        {
                          staticClass: "el-message-box__status el-icon-warning"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "black",
                                "font-size": "8px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Maximum 10000 orders")))]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "topFromSearch", attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "formDiv" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--small is-plain",
                            staticStyle: {
                              float: "right",
                              "margin-left": "0px",
                              "margin-right": "5px !important"
                            },
                            attrs: {
                              type: "primary",
                              size: "small",
                              plain: ""
                            },
                            on: { click: _vm.exportAll }
                          },
                          [_vm._v(_vm._s(_vm.$t("Export All")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "el-button el-button--primary el-button--small",
                            staticStyle: {
                              float: "right",
                              "margin-left": "0px",
                              "margin-right": "5px !important"
                            },
                            attrs: {
                              type: "primary",
                              size: "small",
                              plain: ""
                            },
                            on: { click: _vm.exportCurrent }
                          },
                          [_vm._v(_vm._s(_vm.$t("Export Current")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }